import "./lightbox.scss"

import React, { useState } from "react"

const Lightbox = ({
  children,
  ctaText,
  ctaClass,
  ctaType,
  imageSrc,
  imageAlt,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      {ctaType === "button" ? (
        <button
          type="button"
          onClick={e => setOpen(!open)}
          className={ctaClass}
        >
          {ctaText}
        </button>
      ) : (
        <a
          href="/"
          onClick={e => {
            e.preventDefault()
            setOpen(!open)
          }}
        >
          {children}
        </a>
      )}
      {open && (
        <div className="lightbox" onClick={e => setOpen(!open)}>
          <div className="lightbox__wrapper">
            <span className="lightbox__wrapper-close"></span>
            <img className="img-fluid" src={imageSrc} alt={imageAlt} />
          </div>
        </div>
      )}
    </>
  )
}

export default Lightbox
