import React from "react"

import t from "../locale"
import ImageTween from "../components/imageTween"

const GlobalOffices = ({ lang, color, retail }) => {
  return (
    <section
      className={`offices-grid global__padd--bigger ${
        color === "blue"
          ? "background-blue"
          : color === "green"
          ? "background-green"
          : ""
      }`}
    >
      <h1>{t("Your office with human-centred design", lang)}</h1>

      <div className="row no-gutters">
        <div className="col-md-5">
          <ImageTween
            src={require("../assets/images/offices-content-image-1.jpg")}
            srcSet={require("../assets/images/offices-content-image-1@2x.jpg")}
            alt=""
          />
        </div>
        <div className="col-md-7">
          <div className="offices-grid__inner sticky-wrapper">
            <h3>{t("Exceptional location", lang)}</h3>
            <p>
              {t(
                "The UNITY CENTRE complex is perfectly connected with all parts of Krakow and it is located in the heart of the city in the immediate neighborhood of the University Campus and Botanic Garden. Commute at easy! Here you will benefit from an extensive infrastructure.",
                lang
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="row no-gutters flex-column-reverse flex-md-row">
        <div className="col-md-7">
          <div className="offices-grid__inner sticky-wrapper">
            <h3>{t("Relationship building space", lang)}</h3>
            <p>
              {t(
                "UNITY CENTRE was designed to foster integration and maintenance of natural bonds. The spark of innovation is always born in contact with another person.",
                lang
              )}
            </p>
            <p>
              {t(
                "Feel inspired by UNITY Square and plenty of space to choose from: flexible workspaces and meeting spots, indoor and outdoor co-working spaces, café terraces and tea gardens, canteens and restaurants. The choice is yours!",
                lang
              )}
            </p>
          </div>
        </div>
        <div className="col-md-5">
          {retail ? (
            <ImageTween
              src={require("../assets/images/offices-content-image-22.jpg")}
              srcSet={require("../assets/images/offices-content-image-22@2x.jpg")}
              alt=""
            />
          ) : (
            <ImageTween
              src={require("../assets/images/offices-content-image-2.jpg")}
              srcSet={require("../assets/images/offices-content-image-2@2x.jpg")}
              alt=""
            />
          )}
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-md-5">
          {retail ? (
            <ImageTween
              src={require("../assets/images/offices-content-image-33.jpg")}
              srcSet={require("../assets/images/offices-content-image-33@2x.jpg")}
              alt=""
            />
          ) : (
            <ImageTween
              src={require("../assets/images/offices-content-image-3.jpg")}
              srcSet={require("../assets/images/offices-content-image-3@2x.jpg")}
              alt=""
            />
          )}
        </div>
        <div className="col-md-7">
          <div className="offices-grid__inner sticky-wrapper">
            <h3>{t("Highest space making standards", lang)}</h3>
            <p>
              {t(
                "UNITY CENTRE is equipped with modern technologies, which not only create a friendly ambience but will also reduce operational costs. Green solutions will ensure cost effectiveness while at the same time your organization will be taking care of the environment.",
                lang
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GlobalOffices
