import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalOffices from "../../components/globalOffices"
import ImageTween from "../../components/imageTween"
import Lightbox from "../../components/Lightbox/Lightbox"

const Retail = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/retail/">
      <SEO title="Wynajem przestrzeni" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakowski Kompleks biznesowy</h2>
          </div>
          <ImageTween
            src={require("../../assets/images/_I8A8396.jpg")}
            alt=""
          />
        </div>
      </section>

      <section className="retail-top global__padd--bigger">
        <h2>Na dobry początek</h2>
        <p>
          Nic tak nie tworzy poczucia miejsca jak tętniąca życiem okolica pełna
          sklepików, punktów usługowych, kawiarnii i restauracji. Wpływa to
          pozytywnie na gospodarkę, sprzyja budowaniu dobrej atmosfery
          przestrzeni publicznych i oferuje przedsiębiorcom możliwość rozwoju.
          Mimo wciąż zmieniającej się sytuacji na rynku, potrzeby takie jak
          pielęgnowanie interakcji czy wymiana towarów i usług nigdy nie stracą
          na atrakcyjności. Aranżowanie przestrzeni publicznej w taki sposób z
          pewnością przyniesie korzyści dla całej społeczności i mieszkańców.
        </p>
      </section>

      <section className="retail-content global__padd--bigger">
        <div className="row no-gutters align-items-center">
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Square</h2>
              <p>
                Nowocześnie zaprojektowana i otwarta przestrzeń publiczna to
                podstawowy element życia społecznego w mieście. Centralna część
                naszego kompleksu zachęca do spędzania czasu wolnego w okolicy
                oraz daje możliwość realizacji codziennych potrzeb.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/retail-content-image-1.jpg")}
              srcSet={require("../../assets/images/retail-content-image-1@2x.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters flex-column-reverse flex-md-row align-items-center">
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/retail-content-image-2.jpg")}
              srcSet={require("../../assets/images/retail-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Retail</h2>
              <p>
                Wyjątkowa lokalizacja i tętniąca życiem okolica w centrum
                miasta. To właśnie tu oferujemy zróżnicowane metrażowo
                powierzchnie usługowe. To idealna przestrzeń na Twój nowy sklep
                flagowy, kiosk, restaurację, salon kosmetyczny czy kwiaciarnię.
                Wraz z przestrzenią biurową, mieszkaniową i hotelową, UNITY
                Centre buduje społeczność zorientowaną wokół człowieka i jego
                potrzeb.
              </p>
              <div className="retail-content__inner-action">
                <Lightbox
                  ctaType="button"
                  ctaText="Zobacz lokale"
                  ctaClass="btn-custom-back btn-custom-back--orange"
                  imageSrc={require("../../assets/images/retail-content-image-retail-units-pl-v2.jpg")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row no-gutters align-items-center">
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Hotel</h2>
              <p>
                Hotel Radisson RED to miejsce tętniące życiem, łączące muzykę i
                kreatywność w nowatorski sposób. Już od wejścia do hotelowego
                lobby, ogromne wrażenie robi otwarte atrium z panoramicznymi
                windami oraz lokalny akcent - czerwona rzeźba smoka wawelskiego
                w stylu RED. Obiekt oferuje 229 starannie urządzonych pokoi,
                piętro konferencyjne i strefę fitness. Dodatkowo, hotelowa
                Restauracja Filini zaprasza w kulinarną podróż po Włoszech.
              </p>
              <p>
                Ten czterogwiazdkowy hotel wyróżnia się świeżym podejściem do
                tradycyjnej gościnności, łącząc stylowe wnętrza z energiczną
                atmosferą.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/PG_240619_304.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters flex-column-reverse flex-md-row align-items-center">
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/PG_240619_119.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Residence</h2>
              <p>
                Radisson RED Apartments oferuje 46 przestronnych apartamentów na
                pobyt długoterminowy. Goście mają do wyboru mieszkania typu
                studio lub z wydzieloną sypialnią, które są wyposażone w aneksy
                kuchenne. W większości z tych apartamentów dostępny jest również
                zadaszony taras, co pozwala na relaks na świeżym powietrzu.
              </p>
              <p>
                Odważny design, który łączy nowoczesne elementy, w połączeniu z
                udogodnieniami na wysokim poziomie, zapewnia wyjątkowe wrażenia
                podczas pobytu. Mieszkania zostały zaprojektowane zgodnie z
                hotelowym standardem, oferując wszystko, co potrzebne do
                wygodnego i długotrwałego zakwaterowania.
              </p>
            </div>
          </div>
        </div>
      </section>

      <GlobalOffices lang={lang} retail />
    </Layout>
  )
}

export default Retail
